<!--
 * ======================================
 * 说明： 学生管理  新增 更新
 * 作者： Silence
 * 文件： studentEdit.vue
 * 日期： 2023/8/9 17:03
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
      <el-form-item label="学号" prop="number">
        <el-input v-model="formData.number"></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="realName">
        <el-input v-model="formData.realName"></el-input>
      </el-form-item>
      <el-form-item label="班级" prop="classId">
        <el-cascader class="w-full" v-model="grade" :options="classesTree" :props="{value:'id',label:'name'}"></el-cascader>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-radio-group v-model="formData.sex">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="0">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio-group v-model="formData.status">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="0">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
  </el-form>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      value:Object,
    },
    data() {
      return {
        grade:[],
        classesTree:[],
        formData:{
          realName:'',   //  姓名
          sex:'男',       //  性别
          number:'',      //  学号
          grade:'',       //  年级
          classId:'',   //  班级
          status:1,       //  状态
        },
        rules:{
          realName:[{required: true, message: '请输入学生姓名', trigger: 'blur'}],
          number:[{required: true, message: '请输入学生学号', trigger: 'blur'}],
          classId:[{required: true, message: '请选择班级', trigger: 'blur'}],
        }
      }
    },
    computed: {},
    created() {},
    async mounted() {
       await this.getClassesTree();
       if(this.value.id){
         this.handleLoadForm(this.value.id);
       }else{
         this.formData = {
           realName:'',   //  姓名
           sex:'男',       //  性别
           number:'',      //  学号
           grade:'',       //  年级
           classId:'',   //  班级
           status:1,       //  状态
         }
         this.grade = []
       }
      
    },
    methods: {
      // 获取班级待选树状数据
      async getClassesTree(){
        await this.$cloud.post('/classes/list/tree').then(res=>{
          this.classesTree = res
        })
      },
      // 加载表单数据结构
      handleLoadForm(id){
        this.$cloud.get('/student/detail', {id: id}).then(res => {
          this.formData = {
            id:res.id,
            realName:res.student.real_name,   //  姓名
            sex:res.student.sex,       //  性别
            number:res.student.number,      //  学号
            grade:res.classes.grade,       //  年级
            classId:res.classes.classId,   //  班级
            status:res.student.status,       //  状态
          }
          
          this.grade = [res.classes.grade,res.classes.classId]
        }).catch(() => {})
      },
      
      // 执行数据提交接口
      handleSubmit() {
        this.formData.grade = this.grade[0]
        this.formData.classId = this.grade[1]
        return new Promise((resolve, reject) => {
          this.$refs['form'].validate((valid) => {
            if (valid) {
              let api = '/student/create'
              //判断是否存在ID，如果存在ID，则是修改方法
              if (this.formData.id) {
                api = '/student/edit'
              }
              this.$cloud.post(api, this.formData).then(res => {
                resolve(true)
              }).catch(err => {
                reject(false)
              })
            } else {
              reject(false)
            }
          })
        })
      }
    },
  }
</script>
<style lang="scss" scoped></style>
<template>
  <div class="h-full">
    <Scrollbar>
      <Table :options="handleOptions" :loadData="handleLoadData" title="学生管理" ref="table">
        <template #search>
          <el-form :inline="true" :model="formSearch" class="demo-form-inline">
            <el-form-item label="学号">
              <el-input v-model="formSearch.number" placeholder="学号"></el-input>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input v-model="formSearch.realName" placeholder="姓名"></el-input>
            </el-form-item>
            <el-form-item label="班级">
              <el-cascader clearable v-model="classId" @change="handleClasses" :options="classesTree" :props="{value:'id',label:'name'}" :show-all-levels="false"></el-cascader>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleRefresh">查询</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #action>
          <div>
            <el-button type="primary" @click="handleCreate" icon="el-icon-plus">添加学生</el-button>
<!--            <el-button type="primary" @click="handleExport" icon="el-icon-edit">学生信息导出</el-button>-->
          </div>
        </template>
        <template #handle="{index,row}">
          <el-button type="text" icon="el-icon-edit" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
        </template>
      </Table>
    </Scrollbar>
  </div>
</template>
<script type="text/javascript">
import { head } from 'lodash'
import edit from './components/studentEdit.vue'
export default {
  name: "Student",
  components:{edit},
  data() {
    return {
      classId:[],
      options: {
        header: [],
        search: []
      },
      classesTree:[],
      //搜索表单结构
      formSearch: {
        number: '',
        realName: '',
        classId:'',
      },
    };
  },
  async created() {
    await this.getClassesTree();
    await this.handleRefresh();
  },
  methods: {
    // 获取班级待选树状数据
    async getClassesTree(){
      await this.$cloud.post('/classes/list/tree').then(res=>{
          this.classesTree = res
      })
    },

    handleClasses(){
      this.formSearch['classId'] = ''
      if(this.classId.length){
        this.formSearch['classId'] = this.classId[1]
      }
    },

    handleOptions(table) {
      console.log("获取表格数据源", table)
      //获取分页数据结构
      this.$cloud.get("header/iusers/student").then(res => {
        let {header, search} = res;
        header.map(item=>{
          if(item['prop'] === 'classId'){
            item['prop'] = 'class_name'
          }
          return item;
        })
        table.setHeader(header);
        table.setSearch(search);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    handleLoadData(table, {size, page}, search = {}) {
      //获取分页数据结构
      this.$cloud.get("/student/list", {size, page,...this.formSearch}).then(res => {
        table.setData(res);
      }).catch(err => {
        table.errMsg(err)
      })
    },

    // 添加学生
    handleCreate() {
      this.$cloud.dialog({
        title: '添加学生信息',
        data: {},
        component: edit,
        success: '添加学生成功！',
        warning: '添加学生失败！',
        refresh: this.handleRefresh
      })
    },
    handleEdit(item) {
      this.$cloud.dialog({
        title: '更新学生信息',
        data: item,
        component: edit,
        success: '更新学生成功！',
        warning: '更新学生失败！',
        refresh: this.handleRefresh
      })
    },
    handleDelete(item) {
      console.log("删除",item)
      this.$confirm('此操作将永久删除该学生, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$cloud.post("/student/remove", {id: item.id}).then(res => {
          this.$message.success('删除成功！')
          this.handleRefresh()
        })
      })
    },
    handleExport(){
      this.$message({
        type: 'info',
        message: '导出中，请稍后……'
      });
    },

    // 刷新
    handleRefresh() {
      this.$nextTick(() => {
        this.$refs['table'].resetPage()
        this.$refs['table'].handleLoadData()
      })
    }
  },
};
</script>
<style lang="scss"></style>
